<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">{{ type }}实验报告</div>
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <el-form class="elform" ref="form" :model="form" label-width="80px">
        <el-form-item class="elinput" label="标题:">
          <el-input :disabled="type == '编辑'" v-model="form.title" placeholder="请输入(20字以内)"></el-input>
        </el-form-item>
        <el-form-item class="elinput" label="实验课程:">
          <span style="color: #353535">{{parent.course_lib_name}}</span>
          <!-- <el-select v-model="form.related_id" clearable placeholder="请选择">
            <el-option v-for="item in optionone" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>-->
        </el-form-item>
        <el-form-item class="elinput" label="实验项目:">
          <el-select v-model="form.experiment_id" clearable placeholder="请选择">
            <template v-if="optiontwo && optiontwo.length > 0">
              <el-option
                v-for="item in optiontwo"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item class="elinput2" label="实习报告:">
          <div class="text-editor">
            <Tinymce
              ref="editor"
              v-model="form.content"
              style="height: auto; border-radius: 22px;font-size: 12px;"
              @setInput="getValue"
            ></Tinymce>
          </div>
        </el-form-item>

        <el-form-item class="elinput" label="附件">
          <UploadFiles
            :files="fileList"
            :module="'practice_report,url'"
            :cloudType="'PracticeReport/url'"
            @setUrl="getUrl"
          />
        </el-form-item>
      </el-form>
      <div class="bts">
        <div class="bt" @click="toback()">取消</div>
        <div class="bt bt1" @click="savebt">保存</div>
        <div class="bt bt1" @click="submitbt">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getmyarrangedlist } from "@/api/courselibtimetable"
import { getexperimentlist } from "@/api/experiment"
import {
  addexperimentreport,
  editexperimentreport,
  getexperimentreportinfo,
} from "@/api/experimentreport"

import Tinymce from "@/components/VueTinymce/index.vue"
import UploadFiles from '@/components/upload-files.vue'
export default {
  name: "mainpage",
  components: {
    UploadFiles,
    Tinymce,
  },
  data () {
    return {
      form: {
        id: "",
        title: "",
        content: "",
        related_id: "",
        type: 0,
        experiment_id: "",
        action: "",
        file: '',
      },
      optionone: [],
      optiontwo: [],
      fileList: [],
      type: "新增",
      parent: "", // 父级传参
    }
  },
  computed: {},
  created () {
    this.parent = {
      semester_id: this.$route.query.semester_id || "",
      course_lib_id: this.$route.query.course_lib_id || "",
      course_lib_name: this.$route.query.course_lib_name || "",
    }
    this.form.related_id = this.parent.course_lib_id // 实验课程ID
    this.type = this.$route.query.type ? this.$route.query.type : "新增"
    if (this.type == "编辑") {
      let info = JSON.parse(decodeURIComponent(this.$route.query.info))
      this.form.id = info.id
      this.getReportInfo({ id: info.id }) //获取实验报告详情
    }
    // this.getMyArrangedList(); //获取教学实验课列表
    this.getExperimentList() //获取实验项目列表
  },
  methods: {
    //获取实验报告详情
    getReportInfo (params) {
      getexperimentreportinfo(params).then((response) => {
        if (response.data) {
          this.form.title = response.data.title
          this.form.content = response.data.content
          this.form.related_id = response.data.related_id
          this.form.experiment_id = response.data.experiment_id
          this.form.file = JSON.stringify(response.data.file_list)
          this.fileList = response.data.file_list
        }
      }).catch((error) => {
        //console.log(error)
      })
    },
    //获取教学实验课列表
    getMyArrangedList () {
      let params = { semester_id: this.parent.semester_id }
      getmyarrangedlist(params)
        .then((response) => {
          if (response.code === 0) {
            this.optionone = response.data.data
          }
        })
        .catch((error) => {
          //console.log(error)
        })
    },
    //获取实验项目列表
    getExperimentList () {
      let params = { course_lib_id: this.parent.course_lib_id, is_portal: 1 }
      getexperimentlist(params).then((response) => {
        if (response.code === 0) {
          this.optiontwo = response.data
        }
      }).catch((error) => {
        //console.log(error)
      })
    },
    toback () {
      this.$router.push({
        path: "/home/personalcenter/texperimentalreport",
        query: this.parent,
      })
    },
    //保存
    savebt () {
      this.form.action = 0 //保存
      if (this.type == "新增") {
        this.addexperimentreport(this.form)
      } else if (this.type == "编辑") {
        this.editexperimentreport(this.form)
      }
    },
    //提交
    submitbt () {
      this.form.action = 1 //提交
      if (this.type == "新增") {
        this.addexperimentreport(this.form)
      } else if (this.type == "编辑") {
        this.editexperimentreport(this.form)
      }
    },
    addexperimentreport (params) {
      addexperimentreport(params).then((response) => {
        if (response.code === 0) {
          this.toback()
        }
      }).catch((error) => {
        //console.log(error)
      })
    },
    editexperimentreport (params) {
      editexperimentreport(params)
        .then((response) => {
          if (response.code === 0) {
            this.toback()
          }
        })
        .catch((error) => {
          //console.log(error)
        })
    },
    // 获取富文本得值
    getValue (data) {
      this.form.content = data
    },
    // 获取文件上传的返回值
    getUrl (data, key) {
      this.form.file = data
    }
  },
};
</script>
<style scoped lang="scss">
.mainpage {
  // overflow: hidden;
  background: #ffffff;
  min-width: 1660px;
  // margin-top: 40px;
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .head-title {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      white-space: nowrap;
      margin-top: 10px;
      margin-left: 20px;
    }
    .retutnbt {
      margin-right: 40px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
      margin-top: 10px;
      font-size: 14px;
    }
  }
  .line {
    margin-top: 10px;
    margin-bottom: 4px;
    border-bottom: 2px solid #cccccc;
  }
  .maincontain {
    // height: 1008px;
    background: #ffffff;
    border-radius: 4px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    .elform {
      // border: 1px solid red;
      .elinput {
        width: 30%;
      }
      .elinput2 {
        width: 80%;
      }
      .inputcontain {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .bt {
          padding: 0px;
          margin: 0px;
          margin-left: 10px;
          width: 64px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 4px;
          font-size: 14px;

          font-weight: 400;
          color: #a0a0a0;
          user-select: none;
          cursor: pointer;
        }
        .bt1 {
          background: #3d84ff;
          color: #ffffff;
        }
      }

      .text-editor {
        // border: 1px solid red;
      }
      .file-select-contain {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .file-selecttip {
          margin-left: 10px;
          padding-left: 10px;
          text-align: left;
          width: 200px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #3d84ff;
          border-radius: 4px;
        }
        .file-selectbt {
          margin-left: 10px;
          font-size: 15px;
          font-weight: 400;
          color: #ffffff;
          width: 121px;
          height: 40px;
          line-height: 40px;
          padding: 0px;
          text-align: center;
          background: #3d84ff;
          border: 1px solid #3d84ff;
          border-radius: 4px;
          //  border: 1px solid red;
          &:active {
            background: #3d84ff;
          }
        }
      }
    }
    .bts {
      //   border: 1px solid red;
      margin-top: 40px;
      margin-right: 100px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .bt {
        margin-right: 10px;
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 4px;
        font-size: 14px;

        font-weight: 400;
        color: #a0a0a0;
        user-select: none;
        cursor: pointer;
      }
      .bt1 {
        background: #3d84ff;
        color: #ffffff;
      }
    }
  }
}
</style>
